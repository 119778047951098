import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
import axios from "axios"; // Import Axios
import { toast, ToastContainer } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications
import { useNavigate, useParams } from "react-router-dom"; // For routing (getting coupon ID)

const CouponUpdateForm = () => {
  document.title = "Coupon Update Form | Your App Name";

  const restaurantId = JSON.parse(localStorage.getItem('restaurant'))?.restaurantId || '';
  const { id } = useParams(); // Get coupon ID from URL
  const navigate = useNavigate(); // To redirect after success or error

  const [couponData, setCouponData] = useState(null); // State for fetched coupon data
  const [loading, setLoading] = useState(true); // Loading state
  const [formData, setFormData] = useState({
    code: '',
    startingDate: '',
    endingDate: '',
    discountPercent: '',
    status: 'active',
    description: '',
    timeLimit: '',
    restaurantId: restaurantId,
  });
  const [errors, setErrors] = useState({});

  // Fetch coupon data when the component mounts
  useEffect(() => {
    const fetchCoupon = async () => {
      try {
        const response = await axios.get(`https://foodserver.ashhkaro.com/common/coupons/${id}`);
        setCouponData(response);
        setFormData({
          code: response.code,
          startingDate: response.startingDate,
          endingDate: response.endingDate,
          discountPercent: response.discountPercent,
          status: response.status,
          description: response.description,
          timeLimit: response.timeLimit,
          restaurantId: restaurantId,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching coupon:", error);
        toast.error("Error fetching coupon data. Please try again.");
        setLoading(false);
      }
    };

    fetchCoupon();
  }, [id, restaurantId]);

  // If data is still loading, show a loading message
  if (loading) {
    return <p>Loading...</p>;
  }

  // Validation function
  const validate = () => {
    const newErrors = {};
    if (!formData.code) newErrors.code = "Coupon code is required";
    if (!formData.startingDate) newErrors.startingDate = "Starting date is required";
    if (!formData.endingDate) newErrors.endingDate = "Ending date is required";
    if (new Date(formData.endingDate) <= new Date(formData.startingDate)) newErrors.endingDate = "Ending date must be after starting date";
    if (!formData.discountPercent) newErrors.discountPercent = "Discount percentage is required";
    if (formData.discountPercent < 0 || formData.discountPercent > 100) newErrors.discountPercent = "Discount percentage must be between 0 and 100";
    if (!formData.status) newErrors.status = "Status is required";
    if (formData.timeLimit && (formData.timeLimit <= 0 || !Number.isInteger(Number(formData.timeLimit)))) newErrors.timeLimit = "Time limit must be a positive integer";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validate()) {
      try {
        const response = await axios.put(`https://foodserver.ashhkaro.com/common/coupons/${id}`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log("Success:", response);
        toast.success("Coupon updated successfully!");
        navigate("/table-datatables"); // Redirect to the coupons page (or wherever you want)
      } catch (error) {
        console.error("Error:", error);
        toast.error("Error updating coupon. Please try again.");
      }
    }
  };

  // Handle input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="code">Coupon Code</Label>
                          <Input
                            name="code"
                            placeholder="Enter Coupon Code"
                            type="text"
                            id="code"
                            onChange={handleChange}
                            value={formData.code}
                            invalid={errors.code ? true : false}
                          />
                          {errors.code && <FormFeedback>{errors.code}</FormFeedback>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="startingDate">Starting Date</Label>
                          <Input
                            name="startingDate"
                            type="date"
                            id="startingDate"
                            onChange={handleChange}
                            value={formData.startingDate}
                            invalid={errors.startingDate ? true : false}
                          />
                          {errors.startingDate && <FormFeedback>{errors.startingDate}</FormFeedback>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="endingDate">Ending Date</Label>
                          <Input
                            name="endingDate"
                            type="date"
                            id="endingDate"
                            onChange={handleChange}
                            value={formData.endingDate}
                            invalid={errors.endingDate ? true : false}
                          />
                          {errors.endingDate && <FormFeedback>{errors.endingDate}</FormFeedback>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="discountPercent">Discount Percentage</Label>
                          <Input
                            name="discountPercent"
                            placeholder="Enter Discount Percentage"
                            type="number"
                            id="discountPercent"
                            onChange={handleChange}
                            value={formData.discountPercent}
                            invalid={errors.discountPercent ? true : false}
                          />
                          {errors.discountPercent && <FormFeedback>{errors.discountPercent}</FormFeedback>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="status">Status</Label>
                          <Input
                            type="select"
                            name="status"
                            onChange={handleChange}
                            value={formData.status}
                            invalid={errors.status ? true : false}
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="expired">Expired</option>
                          </Input>
                          {errors.status && <FormFeedback>{errors.status}</FormFeedback>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="description">Description</Label>
                          <Input
                            name="description"
                            placeholder="Enter Description"
                            type="text"
                            id="description"
                            onChange={handleChange}
                            value={formData.description}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="timeLimit">Time Limit (minutes)</Label>
                          <Input
                            name="timeLimit"
                            placeholder="Enter Time Limit"
                            type="number"
                            id="timeLimit"
                            onChange={handleChange}
                            value={formData.timeLimit}
                            invalid={errors.timeLimit ? true : false}
                          />
                          {errors.timeLimit && <FormFeedback>{errors.timeLimit}</FormFeedback>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Update
                    </Button>
                  </Form>
                  <ToastContainer /> {/* Add ToastContainer to render toasts */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CouponUpdateForm;
