import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";

const DealForm = () => {
  document.title = "Deal Form | Upzet - React Admin & Dashboard Template";

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      discountPercentage: "",
      discountActive: false,
      discountDuration: "",
    },
    validationSchema: Yup.object({
      discountPercentage: Yup.number().required("Please Enter Discount Percentage").min(0, "Must be at least 0"),
      discountDuration: Yup.number().required("Please Enter Discount Duration").min(0, "Must be at least 0"),
    }),
    onSubmit: async (values) => {
      const restaurantId = JSON.parse(localStorage.getItem("restaurant"));
      if (!restaurantId) {
        console.error("Restaurant ID not found in localStorage.");
        return;
      }

      const apiUrl = `https://foodserver.ashhkaro.com/common/restaurant/${restaurantId?.restaurantId}/discount`;

      try {
        const response = await axios.put(apiUrl, values, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        console.log("API Response:", response);
        setMessage("Discount successfully updated!");
        setErrorMessage("");

        // Reset form and set timeout to clear message after 5 seconds
        validation.resetForm();
        setTimeout(() => setMessage(""), 5000);
      } catch (error) {
        console.error("Error submitting the form:", error.response ? error.response.data : error.message);
        setErrorMessage("Failed to update discount. Please try again.");
        setMessage("");

        // Set timeout to clear error message after 5 seconds
        setTimeout(() => setErrorMessage(""), 5000);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Deal Form" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title">Deal Form</h4>
                  <p className="card-title-desc">
                    Fill out the details below to create a new deal.
                  </p>
                  {message && <Alert color="success">{message}</Alert>}
                  {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="discountPercentage">Discount Percentage</Label>
                          <Input
                            name="discountPercentage"
                            placeholder="Enter Discount Percentage"
                            type="number"
                            id="discountPercentage"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.discountPercentage}
                            invalid={validation.touched.discountPercentage && validation.errors.discountPercentage ? true : false}
                          />
                          {validation.touched.discountPercentage && validation.errors.discountPercentage ? (
                            <FormFeedback>{validation.errors.discountPercentage}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="discountActive" style={{ marginRight: '6px' }}>Discount Active</Label>
                          <Input
                            name="discountActive"
                            type="checkbox"
                            id="discountActive"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            checked={validation.values.discountActive}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="discountDuration">Discount Duration (days)</Label>
                          <Input
                            name="discountDuration"
                            placeholder="Enter Discount Duration"
                            type="number"
                            id="discountDuration"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.discountDuration}
                            invalid={validation.touched.discountDuration && validation.errors.discountDuration ? true : false}
                          />
                          {validation.touched.discountDuration && validation.errors.discountDuration ? (
                            <FormFeedback>{validation.errors.discountDuration}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit Deal
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DealForm;
