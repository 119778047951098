import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { useProfile } from "../Hooks/UserHooks";

const AuthProtected = (props) => {
  const { userProfile, loading } = useProfile();
  const restaurant = JSON.parse(localStorage.getItem('restaurant'));

  useEffect(() => {
    let inactivityTimer;

    const handleActivity = () => {
      // Clear any existing timer
      clearTimeout(inactivityTimer);

      // Reset the timer
      inactivityTimer = setTimeout(() => {
        localStorage.removeItem('restaurant'); // Clear the restaurant data after 1 hour of inactivity
      }, 3600000); // 1 hour in milliseconds
    };

    // Attach event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);

    // Start the inactivity timer
    handleActivity();

    // Cleanup on component unmount
    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, []);

  // Redirect if restaurant data is not found
  if (!restaurant) {
    return (
      <Navigate to={{ pathname: "/auth-login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return <> <Component {...props} /> </>;
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
