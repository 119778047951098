import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SubcategoryTable = () => {
    const [subcategories, setSubcategories] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState('');
    const restaurantId = JSON.parse(localStorage.getItem('restaurant'))?.restaurantId;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSubcategories = async () => {
            try {
                const response = await axios.get(`https://foodserver.ashhkaro.com/common/subCategory/getSubCategories/${restaurantId}`);
                console.log("Fetched subcategories:", response); // Log the response
                setSubcategories(Array.isArray(response) ? response : []); // Ensure it's an array
            } catch (error) {
                console.error('Error fetching subcategories:', error);
            }
        };

        if (restaurantId) {
            fetchSubcategories();
        }
    }, [restaurantId]);

    const toggleModal = () => {
        setModal(!modal);
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`https://foodserver.ashhkaro.com/common/subCategory/delSubCategories/${selectedSubcategoryId}`);
            setSubcategories(prev => prev.filter(sub => sub._id !== selectedSubcategoryId));
            setDeleteMessage('Subcategory deleted successfully!');
        } catch (error) {
            console.error('Error deleting subcategory:', error.response?.data || error); // Log detailed error
            setDeleteMessage('Failed to delete subcategory');
        } finally {
            setTimeout(() => setDeleteMessage(''), 5000);
            toggleModal();
        }
    };

    const confirmDelete = (id) => {
        setSelectedSubcategoryId(id);
        toggleModal(); // Show modal
    };

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Subcategory Table</h4>

                            {deleteMessage && <div className="alert alert-info">{deleteMessage}</div>} {/* Delete message */}

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}></th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Subcategory Name</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(subcategories) && subcategories?.map((item) => (
                                            <tr key={item._id}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={`checkbox-${item._id}`} />
                                                        <label className="form-check-label" htmlFor={`checkbox-${item._id}`}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {item.name.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-15 mb-0">{item.name}</h5>
                                                </td>
                                                <td>
                                                    <i className={"mdi mdi-checkbox-blank-circle me-1 text-" + (item.status ? 'success' : 'danger')}></i> {item.status ? 'True' : 'False'}
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-outline-success btn-sm me-1" onClick={() => navigate(`/update_Subcategory/${item._id}`)}>Edit</button>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-outline-danger btn-sm me-1"
                                                        onClick={() => confirmDelete(item._id)} // Pass the item's id to confirm delete
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* Confirmation Modal */}
                    <Modal isOpen={modal} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Confirm Deletion</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete this subcategory?
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-secondary" onClick={toggleModal}>Cancel</button>
                            <button className="btn btn-danger" onClick={handleDelete}>OK</button>
                        </ModalFooter>
                    </Modal>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default SubcategoryTable;
