import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Table,
} from "reactstrap";
import axios from "axios"; // Import Axios
import { toast, ToastContainer } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications
import { useNavigate } from "react-router-dom";
const ExpenseForm = () => {
  const [formData, setFormData] = useState({
    amount: '',
    title: '',
    description: '',
  });
  const [errors, setErrors] = useState({});
  const [expenseData, setExpenseData] = useState([]); // To store all submitted expenses
 const navigate = useNavigate()
  // Retrieve restaurantId from localStorage
  const restaurantId = JSON.parse(localStorage.getItem('restaurant'))?.restaurantId || '';

  // Fetch expenses from API when component mounts
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await axios.get(`https://foodserver.ashhkaro.com/common/expense/restaurant/${restaurantId}`);
        setExpenseData(response); // Set the fetched data into the state
      } catch (error) {
        console.error('Error fetching expenses:', error);
        toast.error('Failed to fetch expenses.');
      }
    };

    if (restaurantId) {
      fetchExpenses(); // Fetch data if restaurantId is available
    }
  }, [restaurantId]); // Dependency on restaurantId, so it fetches again if it changes

  // Validate the form data
  const validate = () => {
    const newErrors = {};
    if (!restaurantId) newErrors.restaurantId = "Restaurant ID is required"; // Make sure restaurantId exists
    if (!formData.amount || formData.amount <= 0) newErrors.amount = "Amount must be a positive number";
    if (!formData.title) newErrors.title = "Title is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validate()) {
      try {
        // Submit the form data to the API
        const response = await axios.post('https://foodserver.ashhkaro.com/common/expense', {
          ...formData,
          restaurantId, // Include restaurantId from localStorage
        });

        // Show success toast message
        toast.success("Expense added successfully!");

        // Optionally, fetch the updated expenses list from the server after adding
        const updatedResponse = await axios.get(`https://foodserver.ashhkaro.com/common/expense/restaurant/${restaurantId}`);
        setExpenseData(updatedResponse.data); // Update the state with the new data

        // Reset the form
        setFormData({
          amount: '',
          title: '',
          description: '',
        });
      } catch (error) {
        // Handle any errors during the submission
        console.error('Error submitting expense:', error);
        toast.error('Failed to submit expense.');
      }
    }
  };

  // Handle the delete operation
  const handleDelete = async (id) => {
    try {
      // Delete the expense from the API
      await axios.delete(`https://foodserver.ashhkaro.com/common/expense/${id}`);

      // Remove the deleted expense from the state
      setExpenseData(expenseData.filter(expense => expense.id !== id));

      toast.success("Expense deleted successfully!");
    } catch (error) {
      console.error('Error deleting expense:', error);
      toast.error('Failed to delete expense.');
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    {/* Amount Field */}
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="amount">Amount</Label>
                          <Input
                            name="amount"
                            type="number"
                            id="amount"
                            placeholder="Enter amount"
                            onChange={handleChange}
                            value={formData.amount}
                            invalid={errors.amount ? true : false}
                          />
                          {errors.amount && <FormFeedback>{errors.amount}</FormFeedback>}
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* Title Field */}
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="title">Title</Label>
                          <Input
                            name="title"
                            type="text"
                            id="title"
                            placeholder="Enter title"
                            onChange={handleChange}
                            value={formData.title}
                            invalid={errors.title ? true : false}
                          />
                          {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* Description Field */}
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="description">Description</Label>
                          <Input
                            name="description"
                            type="textarea"
                            id="description"
                            placeholder="Enter description"
                            onChange={handleChange}
                            value={formData.description}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button color="primary" type="submit">Submit Expense</Button>
                  </Form>

                  {/* Display Expenses in a Table */}
                  <h4 className="mt-4">Expense</h4>
                  {expenseData?.length > 0 && (
                    <Table className="mt-2">
                     
                      <thead>
                        <tr>
                          <th>Amount</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {expenseData?.map((expense) => (
                          <tr key={expense._id}>
                            <td>{expense?.amount}</td>
                            <td>{expense?.title}</td>
                            <td>{expense?.description}</td>
                            <td className="d-flex gap-2">
                              <Button
                                color="danger"
                                onClick={() => handleDelete(expense?._id)}
                              >
                                Delete
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => navigate(`/updateExpense/${expense?._id}`)}
                              >
                                Update
                              </Button>
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                  <ToastContainer /> {/* Toast notifications */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ExpenseForm;
