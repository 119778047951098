import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from 'react-router-dom';

const LatestTransaction = () => {
    const [menuData, setMenuData] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const restaurantId = JSON.parse(localStorage.getItem('restaurant'))?.restaurantId; // Get the restaurant ID from local storage
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                const response = await axios.get(`https://foodserver.ashhkaro.com/common/food/${restaurantId}/menu`);
                setMenuData(response); // Use response.data assuming it's the correct structure
                console.log(response);
            } catch (error) {
                console.error('Failed to fetch menu data:', error);
            }
        };

        if (restaurantId) {
            fetchMenuData();
        }
    }, [restaurantId]);

    const toggleModal = () => {
        setModal(!modal);
    };

    const confirmDelete = (id) => {
        setSelectedItemId(id);
        toggleModal(); // Show modal
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`https://foodserver.ashhkaro.com/common/food/${selectedItemId}`);
            setMenuData(prev => prev.map(subcategory => ({
                ...subcategory,
                items: subcategory.items.filter(item => item._id !== selectedItemId)
            })));
        } catch (error) {
            console.error('Error deleting item:', error);
        } finally {
            toggleModal(); // Close modal
        }
    };

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Breadcrumbs title="Product Management" breadcrumbItem="Management table" />
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Products to Manage</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Tag Line</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Discount Price</th>
                                            {/* <th scope="col">Status</th> */}
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {menuData?.map((subcategory, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                {/* Display the subcategory name */}
                                                <tr>
                                                    <td colSpan="7">
                                                        <strong>{subcategory?.subCategory}</strong>
                                                    </td>
                                                </tr>
                                                
                                                {subcategory?.items?.map((item) => (
                                                    <tr key={item._id}>
                                                        <td>
                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input" id={item?._id} />
                                                                <label className="form-check-label" htmlFor={item?._id}></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <h5 className="font-size-15 mb-0">{item?.name}</h5>
                                                        </td>
                                                        <td>{item?.tagLine}</td>
                                                        <td>{item?.price}</td>
                                                        <td>{item?.discountedPrice}</td>
                                                        {/* <td>
                                                            <div className="form-check form-switch form-switch-md mb-3">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`customSwitch-${item?._id}`}
                                                                    style={{ outline: 'none' }}
                                                                    defaultChecked={item.status}
                                                                />
                                                            </div>
                                                        </td> */}
                                                        <td>
                                                            <button type="button" className="btn btn-outline-success btn-sm me-1" onClick={() => { navigate(`/update_Food/${item?._id}`) }}>Edit</button>
                                                            <button type="button" className="btn btn-outline-danger btn-sm me-1" onClick={() => confirmDelete(item._id)}>Delete</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            {/* Confirmation Modal */}
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Confirm Deletion</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this item?
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" onClick={toggleModal}>Cancel</button>
                    <button className="btn btn-danger" onClick={handleDelete}>OK</button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default LatestTransaction;
