import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
import axios from "axios";
import { useLocation, useNavigate, useParams } from 'react-router-dom';  // useNavigate and useParams from react-router-dom v6
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateExpensePage = () => {
  const [formData, setFormData] = useState({
    amount: '',
    title: '',
    description: '',
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate(); // useNavigate for navigation
  const { id } = useParams(); // Get the expense id from the URL parameters

  // Fetch the expense details when the page loads
  useEffect(() => {
    const fetchExpense = async () => {
      try {
        const response = await axios.get(`https://foodserver.ashhkaro.com/common/expense/${id}`);
        setFormData(response); // Populate the form with the fetched data
      } catch (error) {
        console.error('Error fetching expense:', error);
        toast.error('Failed to fetch expense.');
      }
    };

    if (id) {
      fetchExpense();  // Fetch the expense data based on the id
    }
  }, [id]);

  // Validate the form data
  const validate = () => {
    const newErrors = {};
    if (!formData.amount || formData.amount <= 0) newErrors.amount = "Amount must be a positive number";
    if (!formData.title) newErrors.title = "Title is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission to update the expense
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validate()) {
      try {
        // Send PUT request to update the expense data
        const response = await axios.put(`https://foodserver.ashhkaro.com/common/expense/${id}`, formData);
        toast.success("Expense updated successfully!");
        
        // Navigate back to the main expense list page
        navigate('/');
      } catch (error) {
        console.error('Error updating expense:', error);
        toast.error('Failed to update expense.');
      }
    }
  };

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  {/* Amount Field */}
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="amount">Amount</Label>
                        <Input
                          name="amount"
                          type="number"
                          id="amount"
                          placeholder="Enter amount"
                          onChange={handleChange}
                          value={formData.amount}
                          invalid={errors.amount ? true : false}
                        />
                        {errors.amount && <FormFeedback>{errors.amount}</FormFeedback>}
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* Title Field */}
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="title">Title</Label>
                        <Input
                          name="title"
                          type="text"
                          id="title"
                          placeholder="Enter title"
                          onChange={handleChange}
                          value={formData.title}
                          invalid={errors.title ? true : false}
                        />
                        {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* Description Field */}
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="description">Description</Label>
                        <Input
                          name="description"
                          type="textarea"
                          id="description"
                          placeholder="Enter description"
                          onChange={handleChange}
                          value={formData.description}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* Submit Button */}
                  <Button color="primary" type="submit">Update Expense</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ToastContainer /> {/* Toast notifications */}
    </div>
  );
};

export default UpdateExpensePage;
