import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col } from 'reactstrap';

const DataTables = () => {
    const [transactionData, setTransactionData] = useState([]);
    const restaurantId = JSON.parse(localStorage?.getItem('restaurant'))?.restaurantId; // Get the restaurant ID from local storage

    useEffect(() => {
        const fetchRestaurantFees = async () => {
            try {
                const response = await axios.get(`https://foodserver.ashhkaro.com/common/restaurantFeeBought/${restaurantId}`);
                setTransactionData(response); // Assuming response.data is an array of transactions
                console.log(response)
            } catch (error) {
                console.error('Failed to fetch restaurant fees:', error);
                setTransactionData([]); // Set to empty array in case of error
            }
        };

        if (restaurantId) {
            fetchRestaurantFees();
        }
    }, [restaurantId]);

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Restaurant Fees</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Transaction Id</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(transactionData) && transactionData.length > 0 ? (
                                            transactionData.map((item, key) => (
                                                <tr key={key}>
                                                    <td>{item?.transactionId}</td>
                                                    <td>{item?.createdAt}</td>
                                                    <td>{item?.paymentAmount}</td>
                                                    <td>
                                                        <i className={`mdi mdi-checkbox-blank-circle me-1 text-${item?.status ? 'success' : 'danger'}`}></i> 
                                                        {item?.status ? 'True' : 'False'}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className="text-center">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default DataTables;
