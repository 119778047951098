import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, Container, FormFeedback, Form, Alert } from 'reactstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const PaymentForm = () => {
    document.title = "Payment Form | Your Application Name"; // Set your document title

    const { id } = useParams(); // Get featureId from the URL
    const [restaurantId, setRestaurantId] = useState('');
    const [tokens, setTokens] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    async function getAccessToken() {
        try {
            const BASE_URL = "https://ipg1.apps.net.pk/Ecommerce/api/Transaction/GetAccessToken";
            const merchantId = 21288;
            const securedKey = "wNQ3VofFZIiDK7Ke4P6J8eO";

            const response = await axios.post(`${BASE_URL}/token`, {
                MERCHANT_ID: merchantId,
                SECURED_KEY: securedKey,
                BASKET_ID: '',
                TXNAMT: '',
            });

            setTokens(response?.data?.ACCESS_TOKEN);
            console.log(response);
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        getAccessToken();

        const storedRestaurantId = JSON.parse(localStorage.getItem('restaurant'));
        if (storedRestaurantId) {
            setRestaurantId(storedRestaurantId.restaurantId || '');
        }
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            transactionId: '',
            paymentAmount: 0,
        },
        validationSchema: Yup.object({
            transactionId: Yup.string().required("Transaction ID is required"),
            paymentAmount: Yup.number().positive("Payment Amount must be positive").required("Payment Amount is required"),
        }),
        onSubmit: async (values) => {
            try {
                const queryParamsFeatured = new URLSearchParams({
                    paymentAmount: values.paymentAmount,
                    restaurantId: restaurantId,
                    type: 'FeaturedFee',
                    featureId: id,
                    token: tokens,
                }).toString();

                // Open the payment URL in a new tab
                window.open(`http://foodpayment.ashhkaro.com/PaymentForm?${queryParamsFeatured}`, '_blank');

                // Set success message
                setSuccessMessage("Payment submitted successfully!");

                // Clear the form
                validation.resetForm();

                // Clear success message after 5 seconds
                setTimeout(() => {
                    setSuccessMessage("");
                }, 5000);
            } catch (error) {
                console.error("Error:", error);
                // Handle error accordingly
            }
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Forms" breadcrumbItem="Payment Form" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Payment Form</h4>
                                    <p className="card-title-desc">Complete your payment details below.</p>

                                    {successMessage && <Alert color="success">{successMessage}</Alert>} {/* Success message */}

                                    <Form onSubmit={validation.handleSubmit}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="transactionId">Transaction ID</Label>
                                                    <Input
                                                        name="transactionId"
                                                        placeholder="Enter Transaction ID"
                                                        type="text"
                                                        id="transactionId"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.transactionId}
                                                        invalid={validation.touched.transactionId && validation.errors.transactionId ? true : false}
                                                    />
                                                    {validation.touched.transactionId && validation.errors.transactionId ? (
                                                        <FormFeedback>{validation.errors.transactionId}</FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="paymentAmount">Payment Amount</Label>
                                                    <Input
                                                        name="paymentAmount"
                                                        placeholder="Enter Payment Amount"
                                                        type="number"
                                                        id="paymentAmount"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.paymentAmount}
                                                        invalid={validation.touched.paymentAmount && validation.errors.paymentAmount ? true : false}
                                                    />
                                                    {validation.touched.paymentAmount && validation.errors.paymentAmount ? (
                                                        <FormFeedback>{validation.errors.paymentAmount}</FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button color="primary" type="submit">Submit Payment</Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PaymentForm;
