import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const FeaturedTable = () => {
    const [featuredPromotions, setFeaturedPromotions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [restaurantId, setRestaurantId] = useState('');
    const [tokens, setTokens] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPromotions = async () => {
            try {
                const response = await fetch('https://foodserver.ashhkaro.com/common/featured');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data);
                setFeaturedPromotions(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        const getAccessToken = async () => {
            try {
                const BASE_URL = "https://ipg1.apps.net.pk/Ecommerce/api/Transaction/GetAccessToken";
                const merchantId = 21288;
                const securedKey = "wNQ3VofFZIiDK7Ke4P6J8eO";

                const response = await axios.post(`${BASE_URL}/token`, {
                    MERCHANT_ID: merchantId,
                    SECURED_KEY: securedKey,
                    BASKET_ID: '',
                    TXNAMT: '',
                });

                setTokens(response?.data?.ACCESS_TOKEN);
                console.log(response);
            } catch (error) {
                console.log(error.message);
            }
        };

        fetchPromotions();
        getAccessToken();

        const storedRestaurantId = JSON.parse(localStorage.getItem('restaurant'));
        if (storedRestaurantId) {
            setRestaurantId(storedRestaurantId.restaurantId || '');
        }
    }, []);

    const handleBoughtClick = async (featureId, paymentAmount) => {
        try {
            if (!tokens || !restaurantId) {
                console.error("Tokens or restaurantId not set");
                return;
            }

            const queryParamsFeatured = new URLSearchParams({
                paymentAmount: paymentAmount,
                restaurantId: restaurantId,
                type: 'FeaturedFee',
                featureId: featureId,
                token: tokens,
            }).toString();

            // Open the payment URL in a new tab
            window.open(`http://foodpayment.ashhkaro.com/PaymentForm?${queryParamsFeatured}`, '_blank');
        } catch (error) {
            console.error("Error:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Featured Promotions</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Deal</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Cost</th>
                                            <th scope="col">Buy</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {featuredPromotions.map((promotion) => (
                                            <tr key={promotion._id}>
                                                <td>
                                                    <div className="form-check">
                                                        <label className="form-check-label" htmlFor={promotion.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {promotion.name.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{promotion.name}</td>
                                                <td>{promotion.price}</td>
                                                <td>{promotion.createdAt}</td>
                                                <td>{promotion.cost}</td>
                                                <td>
                                                    <Button
                                                        type="button"
                                                        color="success"
                                                        size="sm"
                                                        className="me-1"
                                                        onClick={() => handleBoughtClick(promotion._id, promotion.price)}
                                                    >
                                                        Buy
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default FeaturedTable;
