import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Table,
} from "reactstrap";
import axios from "axios"; // Import Axios
import { toast, ToastContainer } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications

const WithdrawalForm = () => {
  const [formData, setFormData] = useState({
    amount: '',
    accountNumber: '',
    accountTitle: '',
    bankName: '',
  });
  const [errors, setErrors] = useState({});
  const [withdrawalRequests, setWithdrawalRequests] = useState([]); // State for withdrawal requests
  const [loading, setLoading] = useState(false); // Loading state for the requests

  // Get restaurantId from localStorage
  const restaurantId = JSON.parse(localStorage.getItem('restaurant'))?.restaurantId || '';

  // Fetch withdrawal data from the API based on restaurantId
  useEffect(() => {
    if (restaurantId) {
      const fetchWithdrawalRequests = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`https://foodserver.ashhkaro.com/common/walletWithdraw/restaurant/${restaurantId}`);
          setWithdrawalRequests(response); // Set the data to state
        } catch (error) {
          console.error('Error fetching withdrawal requests:', error);
          toast.error('Failed to load withdrawal requests.');
        } finally {
          setLoading(false);
        }
      };

      fetchWithdrawalRequests();
    }
  }, [restaurantId]);

  // Validation function
  const validate = () => {
    const newErrors = {};
    if (!restaurantId) newErrors.restaurantId = "Restaurant ID is required"; // Ensure restaurantId exists
    if (!formData.amount || formData.amount <= 0) newErrors.amount = "Amount must be a positive number";
    if (!formData.accountNumber) newErrors.accountNumber = "Account number is required";
    if (!formData.accountTitle) newErrors.accountTitle = "Account title is required";
    if (!formData.bankName) newErrors.bankName = "Bank name is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validate()) {
      try {
        const response = await axios.post('https://foodserver.ashhkaro.com/common/walletWithdraw', {
          ...formData,
          restaurantId, // Include restaurantId from localStorage
        });
        toast.success("Withdrawal request submitted successfully!");
        // Optionally reset the form
        setFormData({
          amount: '',
          accountNumber: '',
          accountTitle: '',
          bankName: '',
        });

        // Re-fetch the withdrawal requests after successful submission
        const updatedRequests = await axios.get(`https://foodserver.ashhkaro.com/common/walletWithdraw/restaurant/${restaurantId}`);
        setWithdrawalRequests(updatedRequests.data);

      } catch (error) {
        console.error('Error submitting withdrawal request:', error);
        toast.error('Failed to submit withdrawal request.');
      }
    }
  };

  // Delete a withdrawal request by ID
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`https://foodserver.ashhkaro.com/common/walletWithdraw/${id}`);
      toast.success('Withdrawal request deleted successfully');
      // Filter out the deleted request from the state
      setWithdrawalRequests(withdrawalRequests.filter((request) => request._id !== id));
    } catch (error) {
      console.error('Error deleting withdrawal request:', error);
      toast.error('Failed to delete withdrawal request');
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="amount">Amount</Label>
                          <Input
                            name="amount"
                            placeholder="Enter Amount"
                            type="number"
                            id="amount"
                            onChange={handleChange}
                            value={formData.amount}
                            invalid={errors.amount ? true : false}
                          />
                          {errors.amount && <FormFeedback>{errors.amount}</FormFeedback>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="accountNumber">Account Number</Label>
                          <Input
                            name="accountNumber"
                            placeholder="Enter Account Number"
                            type="text"
                            id="accountNumber"
                            onChange={handleChange}
                            value={formData.accountNumber}
                            invalid={errors.accountNumber ? true : false}
                          />
                          {errors.accountNumber && <FormFeedback>{errors.accountNumber}</FormFeedback>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="accountTitle">Account Title</Label>
                          <Input
                            name="accountTitle"
                            placeholder="Enter Account Title"
                            type="text"
                            id="accountTitle"
                            onChange={handleChange}
                            value={formData.accountTitle}
                            invalid={errors.accountTitle ? true : false}
                          />
                          {errors.accountTitle && <FormFeedback>{errors.accountTitle}</FormFeedback>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="bankName">Bank Name</Label>
                          <Input
                            name="bankName"
                            placeholder="Enter Bank Name"
                            type="text"
                            id="bankName"
                            onChange={handleChange}
                            value={formData.bankName}
                            invalid={errors.bankName ? true : false}
                          />
                          {errors.bankName && <FormFeedback>{errors.bankName}</FormFeedback>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit Withdrawal Request
                    </Button>
                  </Form>
                  <ToastContainer /> {/* Add ToastContainer to render toasts */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Table to display withdrawal requests */}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4>Withdrawal Requests</h4>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <Table responsive striped>
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Amount</th>
                          <th>Account Number</th>
                          <th>Account Title</th>
                          <th>Bank Name</th>
                          <th>Date Requested</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {withdrawalRequests?.length > 0 ? (
                          withdrawalRequests?.map((request) => (
                            <tr key={request?._id}>
                              <td>{request?.status}</td>
                              <td>{request?.amount}</td>
                              <td>{request?.accountNumber}</td>
                              <td>{request?.accountTitle}</td>
                              <td>{request?.bankName}</td>
                              <td>{new Date(request?.createdAt).toLocaleString()}</td>
                              <td>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => handleDelete(request?._id)}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center">No withdrawal requests found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WithdrawalForm;
