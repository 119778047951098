import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
const DataTables = () => {
    const [coupons, setCoupons] = useState([]);  // Store fetched coupon data
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);  // Error state

    // Fetch restaurant ID from localStorage
    const restaurantId = JSON.parse(localStorage.getItem('restaurant'))?.restaurantId;

    const navigate = useNavigate(); // To handle navigation

    // Fetch data when the component mounts
    useEffect(() => {
        if (restaurantId) {
            axios.get(`https://foodserver.ashhkaro.com/common/coupons/restaurant/${restaurantId}`)
                .then(response => {
                    setCoupons(response);  // Set coupon data (ensure we use response.data)
                    setLoading(false);
                })
                .catch(err => {
                    setError('Error fetching coupon data');
                    setLoading(false);
                });
        } else {
            setError('Restaurant ID not found in localStorage');
            setLoading(false);
        }
    }, [restaurantId]);

    // Function to handle delete action
    const handleDelete = (couponId) => {
        // Call API to delete the coupon
        axios.delete(`https://foodserver.ashhkaro.com/common/coupons/${couponId}`)
            .then(response => {
                // If the deletion is successful, filter out the deleted coupon from the list
                setCoupons(coupons.filter(coupon => coupon._id !== couponId));
            })
            .catch(err => {
                console.error('Error deleting coupon', err);
                setError('Error deleting coupon');
            });
    };

    // If data is still loading
    if (loading) {
        return <p>Loading...</p>;
    }

    // If there was an error fetching data
    if (error) {
        return <p>{error}</p>;
    }

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Latest Transaction</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Food Item</th>
                                            <th scope="col">Coupon Code</th>
                                            <th scope="col">Time Limit</th>
                                            <th scope="col">Expiry</th>
                                            <th scope="col">Discount Percentage</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {coupons.map((coupon, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={coupon._id} />
                                                        <label className="form-check-label" htmlFor={coupon._id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* Placeholder for the food item image */}
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {coupon?.code?.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-15 mb-0">{coupon?.description || "No Description"}</h5>
                                                </td>
                                                <td>{coupon?.code}</td>
                                                <td>{coupon?.timeLimit}</td>
                                                <td>{coupon?.endingDate}</td>
                                                <td>{coupon?.discountPercent}%</td>
                                                <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 text-${coupon.status === 'active' ? 'success' : coupon.status === 'inactive' ? 'warning' : 'danger'}`}></i> {coupon.status}
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-success btn-sm me-1"
                                                        onClick={() => navigate(`/update-coupon/${coupon._id}`)}
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-danger btn-sm me-1"
                                                        onClick={() => handleDelete(coupon._id)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DataTables;
