import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios"; // Import Axios
import { toast, ToastContainer } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications

const CouponForm = () => {
  document.title = "Coupon Form | Your App Name";

  // Get restaurant ID from local storage
  const restaurantId = JSON.parse(localStorage.getItem('restaurant'))?.restaurantId || '';
console.log(restaurantId)
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
      startingDate: "",
      endingDate: "",
      discountPercent: "",
      valid: true,
      status: "active",
      description: "",
      restaurantId: restaurantId,
      timeLimit: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Coupon code is required").trim(),
      startingDate: Yup.date().required("Starting date is required"),
      endingDate: Yup.date()
        .required("Ending date is required")
        .min(Yup.ref('startingDate'), "Ending date must be after starting date"),
      discountPercent: Yup.number().required("Discount percentage is required").min(0).max(100),
      status: Yup.string().oneOf(['active', 'inactive', 'expired'], "Invalid status").required("Status is required"),
      description: Yup.string().trim(),
      timeLimit: Yup.number().optional().positive().integer(),
    }),
    onSubmit: async (values) => {
      console.log(values)
      try {
        const response = await axios.post("https://foodserver.ashhkaro.com/common/coupons", values, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log("Success:", response);
        // Show success toast
        toast.success("Coupon submitted successfully!");
        // Optionally reset the form or redirect after success
        validation.resetForm();
      } catch (error) {
        console.error("Error:", error);
        // Optionally handle error feedback to the user
        toast.error("Error submitting coupon. Please try again.");
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs className='mb-3' title="Forms" breadcrumbItem="Coupon Form" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="code">Coupon Code</Label>
                          <Input
                            name="code"
                            placeholder="Enter Coupon Code"
                            type="text"
                            id="code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.code}
                            invalid={validation.touched.code && validation.errors.code ? true : false}
                          />
                          {validation.touched.code && validation.errors.code ? (
                            <FormFeedback>{validation.errors.code}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="startingDate">Starting Date</Label>
                          <Input
                            name="startingDate"
                            type="date"
                            id="startingDate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.startingDate}
                            invalid={validation.touched.startingDate && validation.errors.startingDate ? true : false}
                          />
                          {validation.touched.startingDate && validation.errors.startingDate ? (
                            <FormFeedback>{validation.errors.startingDate}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="endingDate">Ending Date</Label>
                          <Input
                            name="endingDate"
                            type="date"
                            id="endingDate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.endingDate}
                            invalid={validation.touched.endingDate && validation.errors.endingDate ? true : false}
                          />
                          {validation.touched.endingDate && validation.errors.endingDate ? (
                            <FormFeedback>{validation.errors.endingDate}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="discountPercent">Discount Percentage</Label>
                          <Input
                            name="discountPercent"
                            placeholder="Enter Discount Percentage"
                            type="number"
                            id="discountPercent"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.discountPercent}
                            invalid={validation.touched.discountPercent && validation.errors.discountPercent ? true : false}
                          />
                          {validation.touched.discountPercent && validation.errors.discountPercent ? (
                            <FormFeedback>{validation.errors.discountPercent}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="status">Status</Label>
                          <Input
                            type="select"
                            name="status"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status}
                            invalid={validation.touched.status && validation.errors.status ? true : false}
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="expired">Expired</option>
                          </Input>
                          {validation.touched.status && validation.errors.status ? (
                            <FormFeedback>{validation.errors.status}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="description">Description</Label>
                          <Input
                            name="description"
                            placeholder="Enter Description"
                            type="text"
                            id="description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="timeLimit">Time Limit (minutes)</Label>
                          <Input
                            name="timeLimit"
                            placeholder="Enter Time Limit"
                            type="number"
                            id="timeLimit"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.timeLimit}
                            invalid={validation.touched.timeLimit && validation.errors.timeLimit ? true : false}
                          />
                          {validation.touched.timeLimit && validation.errors.timeLimit ? (
                            <FormFeedback>{validation.errors.timeLimit}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                  <ToastContainer /> {/* Add ToastContainer to render toasts */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CouponForm;
