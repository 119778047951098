import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const restaurantData = JSON.parse(localStorage.getItem('restaurant'));
    const restaurantId = restaurantData ? restaurantData.restaurantId : null;

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`https://foodserver.ashhkaro.com/common/orders/seller/${restaurantId}`);
                setOrders(response); 
                console.log(response);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    const toggleDropdown = (orderId) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [orderId]: !prevState[orderId]
        }));
    };

    const handleStatusChange = async (orderId, newStatus) => {
        console.log(orderId, newStatus);
        try {
            await axios.patch(`https://foodserver.ashhkaro.com/common/orders/${orderId}/status`, { status: newStatus });

            setOrders(prevOrders =>
                prevOrders.map(order =>
                    order._id === orderId ? { ...order, status: newStatus } : order
                )
            );

            alert(`Order ID ${orderId} cooking status updated to ${newStatus}`);
        } catch (error) {
            alert(`Failed to update status for Order ID ${orderId}: ${error.message}`);
        }
    };

    if (loading) return <p>Loading orders...</p>;
    if (error) return <p>Error fetching orders: {error.message}</p>;

    // Filter out orders with the status "Preparing"
    const filteredOrders = orders.filter(order => order.status == 'delivered');

    return (
        <React.Fragment>
            <Row style={{ paddingLeft: "30px", paddingRight: "30px", marginTop: "100px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Delivered</h4>
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}></th>
                                            <th scope="col">Items</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Invoice ID</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">Cooking Status</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredOrders.map((order) => (
                                            order.items.map((item, index) => (
                                                <tr key={`${order._id}-${index}`}>
                                                    <td>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id={order._id} />
                                                            <label className="form-check-label" htmlFor={order._id}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="avatar-xs">
                                                            <span className="">
                                                                {item.name}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.price}</td>
                                                    <td>
                                                        {order.invoiceId}
                                                        <p style={{ fontSize: '10px' }}>{order.paymentType}</p>
                                                    </td>
                                                    <td>{order.totalPrice.toFixed(2)}</td>
                                                    <td>
                                                        <i className={`mdi mdi-checkbox-blank-circle me-1 `}></i>
                                                        {order.status}
                                                    </td>
                                                    <td>
                                                        <Dropdown isOpen={dropdownOpen[order._id] || false} toggle={() => toggleDropdown(order._id)}>
                                                            <DropdownToggle caret color="primary" size="sm">
                                                                Change Status
                                                            </DropdownToggle>
                                                            <DropdownMenu style={{ minWidth: '150px', maxHeight: '200px', overflowY: 'auto' }}>
                                                                <DropdownItem onClick={() => handleStatusChange(order._id, 'Preparing')}>
                                                                    Preparing
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => handleStatusChange(order._id, 'Cooking')}>
                                                                    Cooking
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => handleStatusChange(order._id, 'Confirmed')}>
                                                                    Confirmed
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => handleStatusChange(order._id, 'Ready')}>
                                                                    Ready
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => handleStatusChange(order._id, ' Way')}>
                                                                  Way
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => handleStatusChange(order._id, 'Refund')}>
                                                                 Refund
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => handleStatusChange(order._id, 'Delieverd')}>
                                                                    Delieverd
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => handleStatusChange(order._id, 'Request')}>
                                                                   Request
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => handleStatusChange(order._id, 'Canceled')}>
                                                                    Canceled
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Orders;
